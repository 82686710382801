:root {
  --mui-primary-color: #c62828;
  --mui-secondary-color: #3949ab;
  --primary-background-color: #fffefc;
  --light-text-color: rgba(0,0,0,0.46);
  --text-color: rgba(0,0,0,0.87);
  --main-width: 728px;
}

.main {
}

.feed {
  max-width: var(--main-width);
  margin: 0 auto;
  text-align: left;
  padding: 8px;
}

.logo {
  font-family: "Lora", -apple-system, Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin: 8px 0px;
  text-decoration: none;
  color: var(--mui-primary-color);
}

.clean-link {
  text-decoration: none;
  color: inherit;
}

footer {
  max-width: var(--main-width);
  margin: 48px auto 16px;
  font-size: 12px;
  color: var(--light-text-color);
}
